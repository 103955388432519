export interface IPostActivityRequestDto {
    activityType: ActivityTypeEnum;
    linkedItemType: LinkedItemTypeEnum;
    linkedUrl: string;
    pageId?: string;
    pageItemId?: string;
    componentId?: string;
    componentItemId?: string;
}

export enum ActivityTypeEnum {
    OpenUrl = 'open_url',
}

export enum LinkedItemTypeEnum {
    Component = 'component',
    Page = 'page',
    ImageLinkZone = 'image_link_zone',
}
